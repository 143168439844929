export const Forms = () => {
  // grab everything from the html form

  // container
  const formOverlay = document.querySelector("#form-overlay");
  const menu = document.querySelector("#menu");

  // form entrance
  const buttonAndroid = document.querySelector("#buttonAndroid");
  const buttonAmbassador = document.querySelector("#buttonAmbassador");

  // close icon

  const formClose = document.querySelector("#formClose");


  // background
  const animatedText = document.querySelector(".animated-text");

  //  messages
  const errorMessage = document.querySelector(".message--warning");
  const successMessage = document.querySelector(".message--success");

  // android form
  const formAndroid = document.querySelector("#form-android");
  const androidSlide1 = document.querySelector("#android_full_name");
  const androidSlide2 = document.querySelector("#android_phone_email");
  const androidButton1 = document.querySelector("#android_1");
  const androidButton2 = document.querySelector("#android_submit");

  // ambassador form
  const formAmbassador = document.querySelector("#form-ambassador");
  const ambassadorSlide1 = document.querySelector("#ambassador_opportunities");
  const ambassadorSlide2 = document.querySelector("#ambassador_name");
  const ambassadorSlide3 = document.querySelector("#ambassador_socials");
  const ambassadorSlide4 = document.querySelector("#ambassador_phone_email");
  const ambassadorButton1 = document.querySelector("#ambassador_1");
  const ambassadorButton2 = document.querySelector("#ambassador_2");
  const ambassadorButton3 = document.querySelector("#ambassador_3");
  const ambassadorButton4 = document.querySelector("#ambassador_submit");

  // call this whenever the form overlay is closed to reset it

  function totalRecall() {
    let removeActive = [
      successMessage,
      errorMessage,
      androidSlide2,
      ambassadorSlide2,
      ambassadorSlide3,
      ambassadorSlide4
    ];

    removeActive.forEach(function(el) {
      el.classList.remove("active");
    });

    let addActive = [androidSlide1, ambassadorSlide1];

    addActive.forEach(function(el) {
      el.classList.add("active");
    });

    formOverlay.classList.remove(
      "ambassador-active",
      "overlay-active",
      "android-active"
    );

    let removeOverlay = [animatedText, menu];
    removeOverlay.forEach(function(el) {
      el.classList.remove("overlay-active");
    });
  }

  // link buttons to input state
  const fieldsets = document.querySelectorAll(".fieldset");
  for (var i = 0; i < fieldsets.length; i++) {
    const fieldset = fieldsets[i];
    const input = fieldset.querySelector("input");
    const button = fieldset.querySelector("button");

    addEventListener("input", function(e) {
      if (input && input.value) {
        //console.log("input!");
        button.classList.remove("disabled");
      } else {
        //console.log("no input");
        button.classList.add("disabled");
      }
    });

    addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }

  //// link buttons to next q/submit
  function androidFlow() {
    // android  continue buttons (name -> email)
    androidButton1.addEventListener("click", function() {
      androidSlide1.classList.remove("active");
      androidSlide2.classList.add("active");
    });

    // android submit button (email -> submit)
    formAndroid.addEventListener("submit", function(event) {
      event.preventDefault(); // block submit button page refresh
      
      let formData = new FormData(event.target)
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          androidSlide2.classList.remove("active");
          successMessage.classList.add("active"); // show success
    
          setTimeout(() => {
            totalRecall();
          }, 2000); // wait 2s for message
        })
        .catch((error) => alert(error));
    });
  }
  function ambassadorFlow() {
    // ambassador continue buttons (opportunities -> name)
    ambassadorButton1.addEventListener("click", function() {
      ambassadorSlide1.classList.remove("active");
      ambassadorSlide2.classList.add("active");
    });

    // ambassador 2 (name -> socials)
    ambassadorButton2.addEventListener("click", function() {
      ambassadorSlide2.classList.remove("active");
      ambassadorSlide3.classList.add("active");
    });

    // ambassador 3 (socials -> email)
    ambassadorButton3.addEventListener("click", function() {
      ambassadorSlide3.classList.remove("active");
      ambassadorSlide4.classList.add("active");
    });

    // ambassador submit button (email -> submit)
    formAmbassador.addEventListener("submit", function(event) {
      event.preventDefault(); // block submit button page refresh

      let formData = new FormData(event.target)
      console.log([...formData])
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          ambassadorSlide4.classList.remove("active");
          successMessage.classList.add("active"); // show success
    
          setTimeout(() => {
            totalRecall();
          }, 2000); // wait 2s for message
        })
        .catch((error) => alert(error));
    });
  }

  function openOverlay() {
    formOverlay.classList.add("overlay-active");
    animatedText.classList.add("overlay-active");
    menu.classList.add("overlay-active");
  }

  // Activate Footer Buttons
  // buttonAndroid.addEventListener("click", function() {
  //   openOverlay();
  //   formOverlay.classList.add("android-active");
  //   androidFlow();
  // });
  // buttonAmbassador.addEventListener("click", function() {
  //   openOverlay();
  //   formOverlay.classList.add("ambassador-active");
  //   ambassadorFlow();
  // });

  // Escape to Close Overlay

  document.addEventListener("keydown", function(event) {
    if (event.key === "Escape") {
      totalRecall();
    }
  });

  // Icon to Close Overlay

  formClose.addEventListener("click", function() {
    totalRecall();
  });

}
