const close = document.getElementById("navClose");
const back = document.getElementById("navBack");
const burger = document.getElementById("burger");
const careers = document.getElementById("careers");
const links = document.getElementById("links");
const socials = document.getElementById("socials");

try {
  burger.onclick = function() {
    openMenu();
  };

  close.onclick = function() {
    closeMenu();
  };

  back.onclick = function() {
    closeSecondary();
  };
} catch(e) {
  console.warn(e)
}

function openMenu() {
  menu.classList.add("open");
  menu.classList.remove("close");
}

function closeMenu() {
  menu.classList.remove("open");
  menu.classList.add("close");
  closeSecondary();
}

function openSecondary() {
  menu.classList.add("secondary-active");

  careers.classList.remove("hidden");
  back.classList.remove("hidden");

  links.classList.add("hidden");
  socials.classList.add("hidden");
}

function closeSecondary() {
  menu.classList.remove("secondary-active");

  careers.classList.add("hidden");
  back.classList.add("hidden");

  links.classList.remove("hidden");
  socials.classList.remove("hidden");
}
